<template lang="pug">
    .landing1
        v-snackbar.alert-snack(
            :timeout="3000",
            :top="true"
            :right="true"
            color="error"
            v-model="error")
                | {{ errorText }}
                v-btn(flat, dark, @click.native="error = false") {{ $t('cerrar') }}
        .l-header(xs12)
            span J U I C I O &nbsp;&nbsp;A&nbsp;&nbsp;  E X P R E S I D E N T E S
        v-layout.max-width(row wrap)
            v-flex.pa-0(xs12)
                img.full-width(src="/public/img/landings/banner.png")
            v-flex(xs12)
                h2.text-xs-center.mt-4.mb-2 Descarga los audios para difundir la información
                v-layout(row wrap)
                    v-flex(xs12 sm6 md4 v-for="(v, k) in 9" :key="k")
                        v-layout.audio-player(row wrap)
                            v-flex(xs4)
                                img.full-width.cover-img(src="/public/img/landings/cover.png")
                            v-flex(xs8)
                                span.song-name(data-amplitude-song-info="name" :data-amplitude-song-index="k")
                                v-layout.controls(row wrap)
                                    v-flex(xs2)
                                        .amplitude-play-pause(:data-amplitude-song-index="k")
                                    v-flex(xs4)
                                        progress.amplitude-song-played-progress(:id="'song-played-progress-'+k" :data-amplitude-song-index="k")
                                    v-flex(xs4)
                                        .duration
                                            span.amplitude-current-minutes(:data-amplitude-song-index="k")
                                            | :
                                            span.amplitude-current-seconds(:data-amplitude-song-index="k")
                                            | /
                                            span.amplitude-duration-minutes(:data-amplitude-song-index="k")
                                            | :
                                            span.amplitude-duration-seconds(:data-amplitude-song-index="k")
                                    v-flex(xs2)
                                        a(:href="audioUrls[k]" target="__blank"): img.download(src="/public/img/landings/download.png")
            v-flex(xs12)
                h2.text-xs-center.mt-4.mb-2 Descarga los PDF's para difundir información
                div.mx-auto.text-xs-center.d-block.mt-4
                    a.ma-3(href="/public/pdf/pdf1.pdf" target="__blank")
                        img(src="/public/img/landings/pdf.png")
                    a.ma-3(href="/public/pdf/pdf2.pdf" target="__blank")
                        img(src="/public/img/landings/pdf.png")
            v-flex(xs12)
                img.text1.d-block.mx-auto(src="/public/img/landings/text.png")
            v-flex(xs12)
                img.full-width(src="/public/img/landings/exp.png")
            v-flex(xs12)
                img.text2.d-block.mx-auto(src="/public/img/landings/text2.png")
            v-flex(xs12)
                p.text3.d-block.mx-auto.text-xs-center
                    | ¿Estás de acuerdo que las autoridades correspondientes realicen una investigación sobre presuntos actos ilícitos que hayan causado afectaciones o daños graves al país, realizados por los 
                    span(style="font-weight:bold;") expresidentes de México 
                    | y, en su caso, se inicie un 
                    span procedimiento judicial 
                    | que garantice el debido proceso?
                h3.join.py-4(@click="openRegister"): a Haz click aquí y ¡Súmate!
        v-dialog(v-model="registerModal" max-width="1024" persistent)
            v-form
                v-card
                    v-card-title
                        .btns.btns-right
                            v-btn(icon color="secondary" small @click.native="registerModal=false")
                                v-icon close
                        h4.mx-auto {{ $t('LA CONSULTA POPULAR DE JUCIO A EXPRESIDENTES ¡VA!') }}
                    v-card-text
                        p.text-xs-center SOMOS MÁS DE 2.1 MILLONES DE PERSONAS LAS QUE PARTICIPAMOS EN EL PROCESO DE RECOLECCIÓN DE FIRMAS PARA LLEVAR A JUICIO A LOS EXPRESIDENTES. NUESTRO COMPROMISO NO HA TERMINADO, EL 01 DE AGOSTO DEL 2021 SE LLAVARÁ A CABO LA CONSULTA POPULAR Y ES NECESARIO QUE PARTICIPEMOS ACTIVAMENTE POR LA DEFENSA Y PROMOCIÓN DE ELLA.
                        p.text-xs-center LA DEMOCRACIA LA CONSTRUIMOS DIARIO, DESDE ABAJO, CON Y PARA EL PUEBLO.
                        p.text-xs-center CONSTRUYAMOS PODER POPULAR
                        p.text-xs-center ¡SÚMATE Y REGÍSTRATE!
                        h2.mt-4.secondary--text {{ $t('Regístrate aquí si aún no tienes cuenta')}} 
                            template(v-if="!activeNetwork") {{$t('en la red')}} {{ this.currentNetwork.name }}
                        v-layout(row wrap)
                            template(v-if="!initialData")
                                v-flex(xs12)
                                    h3(v-if="hasMexico") {{$t('Ingresa tu correo electrónico o teléfono o ID y una contraseña')}}
                                    h3(v-else) {{$t('Ingresa tu correo electrónico o teléfono y una contraseña')}}
                                template(v-if="!activeNetwork")
                                    v-flex(xs12)
                                        v-text-field(v-if="hasMexico" v-model="step.username", :label="$t('Correo electrónico o teléfono o ID')" type="text" required)
                                        v-text-field(v-else v-model="step.username", :label="$t('Correo electrónico o teléfono')" type="text" required)
                                    v-flex(xs12 v-if="['email', 'phone', 'username'].includes(validateUser)")
                                        v-text-field(v-if="validateUser == 'email'" v-model="step.rusername", :label="$t('Confirma tu correo electrónico')", type="text", required)
                                        v-text-field(v-if="validateUser == 'phone'" v-model="step.rusername", :label="$t('Confirma tu teléfono')", type="text", required)
                                        v-text-field(v-if="hasMexico && validateUser == 'username'" v-model="step.rusername", :label="$t('Confirma tu ID')" type="text" required)
                                template(v-else)
                                    v-flex(xs12)
                                        v-text-field(v-model="step.email", :label="$t('Correo electrónico')", type="email", required)
                                    v-flex(xs12)
                                        v-text-field(v-model="step.remail", :label="$t('Confirma tu correo electrónico')", type="email", required)
                                v-flex(xs12)
                                    v-text-field(
                                        v-model="step.password" 
                                        :label="$t('Contraseña')"
                                        required
                                        :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                        :append-icon-cb="() => (e1 = !e1)"
                                        :type="e1 ? 'password' : 'text'")
                                    small {{$t('Cree una nueva contraseña para este sitio')}}
                                v-divider.mt-4
                                v-flex(xs12 align-center)
                                    v-layout.pa-3(align-center hide-details v-if="!activeNetwork")
                                        v-checkbox(v-model="terms" v-if="!activeNetwork")
                                        p.ml-2
                                            | {{$t('Acepto')}} 
                                            a(@click="termsModal=true") {{$t('los términos y condiciones y las políticas de privacidad')}} 
                                            | {{$t('de este sitio con referencia al cumplimiento de la ley general de protección datos personales para el sector público')}}.
                                v-flex(xs12 v-if="terms")
                                    .btns.btns-right
                                        v-btn(small color="primary" depressed @click.native="check" :loading="loadCheck") {{ $t('Siguiente') }}
                            v-divider.mt-4
                            template(v-if="initialData")
                                template(v-if="!activeNetwork")
                                    template(v-if="validateUser == 'email'")
                                        v-flex(xs12)
                                            v-text-field(v-model="step.email", :label="$t('Correo electrónico o teléfono')", type="text", required)
                                        v-flex(xs12)
                                            v-text-field(v-model="step.remail", :label="$t('Confirma tu correo electrónico')", type="text", required)
                                template(v-else)
                                    v-flex(xs12)
                                        v-text-field(v-model="step.email", :label="$t('Correo electrónico')", type="email", required)
                                    v-flex(xs12)
                                        v-text-field(v-model="step.remail", :label="$t('Confirma tu correo electrónico')", type="email", required)
                                v-flex(xs12)
                                    v-text-field(
                                        v-model="step.password" 
                                        :label="$t('Contraseña')"
                                        required
                                        :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                        :append-icon-cb="() => (e1 = !e1)"
                                        :type="e1 ? 'password' : 'text'")
                                    small {{$t('Cree una nueva contraseña para este sitio')}}
                                v-flex(xs12)
                                    v-text-field(v-model='step.names', :label='$t("Nombre(s)")', required)
                                v-flex(xs12)
                                    v-text-field(v-model='step.surname', :label='$t("Primer apellido")', required)
                                v-flex(xs12)
                                    v-text-field(v-model='step.surname2' :label='$t("Segundo apellido")')
                                v-flex(xs12 v-if="isAvailable('countryid')")
                                    v-select(
                                        v-model="step.countryid"
                                        :items='country',
                                        item-value="uuid",
                                        item-text="name", 
                                        :label='$t("País")', 
                                        append-icon="keyboard_arrow_down", 
                                        autocomplete
                                        :filter="vFilter"
                                        required
                                        @input="fetchState"
                                    )
                                v-flex(xs12 v-if="isAvailable('stateid')")
                                    v-select(
                                        v-model="step.stateid"
                                        :items='state',
                                        item-value="uuid",
                                        item-text="name", 
                                        :label='$t("Estado/Departamento/Provincia")', 
                                        append-icon="keyboard_arrow_down", 
                                        autocomplete
                                        :filter="vFilter"
                                        required
                                        @input="fetchCity"
                                    )
                                v-flex(xs12 v-if="isAvailable('cityid')")
                                    v-select(
                                        v-model="step.cityid"
                                        :items='city',
                                        item-value="uuid",
                                        item-text="name", 
                                        :label='$t("Ciudad/Municipio")', 
                                        append-icon="keyboard_arrow_down", 
                                        autocomplete
                                        :filter="vFilter"
                                        required
                                        @change="zonei=-1,zones=[]"
                                        @input="fetchZone"
                                    )
                                template(v-if="zones.length && isAvailable('zones')")
                                    v-flex(xs12 v-for="(zone, i) in zones" :key="'z'+i")
                                        v-select(
                                            v-model="step.zones[i]"
                                            :items='zone',
                                            item-value="uuid",
                                            item-text="name", 
                                            :label='zone[0].division', 
                                            append-icon="keyboard_arrow_down", 
                                            autocomplete
                                            :filter="vFilter"
                                            required
                                            @change="zonei=i"
                                            @input="fetchZone"
                                        )
                                v-flex(xs12)
                                    v-text-field(:prefix="phone" v-model='step.phone', :label='$t("Celular (WhatsApp)")', required)
                                v-flex(xs12 v-if="isAvailable('gender')")
                                    v-select(
                                        v-model="step.gender"
                                        :items='gender', 
                                        item-value="id", 
                                        item-text="text" 
                                        :label='$t("Género")', 
                                        append-icon="keyboard_arrow_down", 
                                        autocomplete
                                        :filter="vFilter"
                                        required
                                    )
                                v-flex(xs12 v-if="isAvailable('birthdate')")
                                    v-dialog(ref='dialogBirthdate' persistent v-model='modalBirthdate' lazy full-width width='290px', :return-value.sync='step.birthdate')
                                        v-text-field(slot='activator' :label='$t("Fecha de nacimiento")' v-model='step.birthdate' readonly hide-details)
                                        v-date-picker(:locale="$root.currentLanguage" v-model='step.birthdate' ref="birthdate" scrollable)
                                            v-spacer
                                            v-btn(flat color='primary' @click='modalBirthdate = false') {{$t('Cancelar')}}
                                            v-btn(flat color='primary' @click='$refs.dialogBirthdate.save(step.birthdate)') {{$t('OK')}}
                                v-flex(xs12 v-if="(validateUser == 'username' && hasMexico) || isAvailable('document')")
                                    v-text-field(v-model='step.document' :label='$t("Número de documento de identificación")')
                                v-flex(xs12 v-show="false")
                                    v-text-field(v-model="step.code" :label="$t('Código de invitación')")
                                v-flex(xs12 v-for="(c, i) in customForm" :key="'c'+i")
                                    v-text-field(v-if="c.type == 1" v-model="step.custom[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                    v-text-field(multi-line v-if="c.type == 5" v-model="step.custom[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                    template(v-if="c.type == 2")
                                        label.d-block.mb-1 {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                        v-checkbox(v-for="(opt, i) in c.options" v-model="step.custom[c.uuid]" :key="'opt'+i" :label="(typeof opt == 'string' ? opt : opt.option)" :value="(typeof opt == 'string' ? opt : opt.option)")
                                    template(v-if="c.type == 4")
                                        v-select(clearable v-if="c.depends == null ? true : !c.depends.length" v-model="step.custom[c.uuid]" :return-object="true" :items="c.options" item-value="uuid" item-text="option" :label="c.placeholder" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" :required="c.required" @input="computedDepends(c, $event)")
                                        v-select(clearable v-else v-model="step.custom[c.uuid]" :items="c.hasOwnProperty('coptions') ? c.coptions : []" :required="c.required" item-value="uuid" item-text="option" :label="c.placeholder" :return-object="true" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" @input="computedDepends(c, $event)")
                                    v-radio-group(v-if="c.type == 3" v-model="step.custom[c.uuid]")
                                        label {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                        v-radio(v-for="(opt, i) in c.options" :key="'opt'+i" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")
                        template(v-if="initialData")
                            v-divider.mt-4
                            .btns.btns-right
                                v-btn(v-if="terms" color="primary" large @click.native="save" :loading="loadingBtn") {{ $t('Registrarse') }}
                        div.mt-4(style="width:100%")
                            h2.secondary--text
                                | {{$t('Ingresa')}}&nbsp;
                                router-link(to="/login") {{$t('aquí')}}&nbsp;
                                | {{$t('si ya tienes una cuenta')}} 
                                template(v-if="!activeNetwork") {{$t('en la red')}} {{ this.currentNetwork.name }}
                                
        v-dialog(v-if="!activeNetwork" v-model="termsModal" max-width="600") 
            v-card
                v-card-text
                    v-tabs(
                        v-model="termActive"
                        color="white"
                        outline
                        slider-color="primary"
                    )
                        v-tab(:key="0") {{$t('Términos y condiciones')}}
                        v-tab(:key="1") {{$t('Políticas de privacidad')}} 
                        v-tab-item(:key="0")
                            v-card.mt-2(flat v-html="currentNetwork.terms")
                        v-tab-item(:key="1")
                            v-card.mt-2(flat v-html="currentNetwork.priv")
                v-card-actions
                    .btns.btns-right
                            v-btn(color="secondary" outline @click.native="termsModal = false") {{$t('Salir')}} 
</template>

<script>

    import fn from 'mixins/fn'
    import _m from 'lodash/merge'
    
    export default {
        mixins: [fn],
        metaInfo (){
            return {
                title: this.$t('Juicio a expresidentes'),
                link: [
                    {
                        rel: 'icon',
                        href: '/public/img/landings/favicon.jpg'
                    }
                ]
            }
        },
        data(){
            return {
                registerModal: false,
                audioUrls: [
                    'https://drive.google.com/file/d/1EwVM7IW8-zaHV2iktkAnjtY_knQGuyT5/view?usp=sharing',
                    'https://drive.google.com/file/d/1urDbz3TzM0OAv0_rUxP0rXTO1QhXTnGh/view?usp=sharing',
                    'https://drive.google.com/file/d/1oJcOPXJ5wIvj2vIMJ3n1dlZ7MzcUDfqb/view?usp=sharing',
                    'https://drive.google.com/file/d/1jiz4qTa7_oDS4AmYJXBT2aFeep89eCxa/view?usp=sharing',
                    'https://drive.google.com/file/d/12GL1l2DS_DvicrGHxKWjOPCwQ-NslzW-/view?usp=sharing',
                    'https://drive.google.com/file/d/1GjNObI4IK2zKjxC2o_QKo0OEzv-LxNX4/view?usp=sharing',
                    'https://drive.google.com/file/d/1Kt7lCUGfdzAsVZpBjppw-yD19xlL2Msn/view?usp=sharing',
                    'https://drive.google.com/file/d/1GFJ4kM5fcW24f72O1UWD8jJw5SWtuMRG/view?usp=sharing',
                    'https://drive.google.com/file/d/1yMAU9f_6ZjmL26m7chps-qPZm2LAAfdL/view?usp=sharing'
                    
                ],

                modalBirthdate: false,
                terms: false,
                termActive: 0,
                termsModal: false,
                e1: true,
                register : 0,
                state: [],
                city: [],
                country: [],
                zones: [],
                zonei: -1,
                step : {
                    names     : '',
                    surname   : '',
                    surname2  : '',
                    username  : '',
                    rusername : '',
                    email    : '',
                    remail   : '',
                    password : '',
                    phone    : '',
                    countryid: '',
                    stateid  : '',
                    cityid   : '',
                    gender   : '',
                    birthdate: null,
                    code     : '',
                    networkid: '',
                    zones    : [],
                    document : '',
                    custom   : {}
                },
                userid: '',
                error: false,
                errorText: '',
                loadOptions: {
                    normal: 'Registrarse',
                    load: 'Registrando...'
                },
                loadDisabled: false,
                loadState: '',
                loadCheck: false,
                loadingBtn: false,
                hasData: false,
                initialData: false,
                foundData: {},
                customForm: [],
                availableRow: [],
                loadPage: false
            }
        },
        watch: {
            modalBirthdate (val) {
                val && this.$nextTick(() => (this.$refs.birthdate.activePicker = 'YEAR'))
            },
            step: {
                deep: true,
                handler(){
                    
                    if(this.hasMexico && this.validateUser == 'username'){
                        
                        this.step.document  = this.step.username.toUpperCase()
                        this.step.username  = this.step.username.toUpperCase()
                        this.step.rusername = this.step.rusername.toUpperCase()
                    }
                }
            },
            currentNetwork: {
                handler() {
                    
                    if(this.currentNetwork){
                        
                        if (this.hasOwnProperty('step')) {
                            this.step.networkid = this.currentNetwork.uuid
                        }
                        if (this.hasOwnProperty('networkid')) {
                            this.networkid = this.currentNetwork.uuid
                        }
                        if (this.hasOwnProperty('form')) {
                            this.form.networkid = this.currentNetwork.uuid
                        }

                        this.$root.networkFound = this.currentNetwork
                    }

                    this.activeNetwork = this.currentNetwork ? false : true
                    this.registerUrl   = this.currentNetwork ? this.currentNetwork.register && (this.currentNetwork.register != null && this.currentNetwork.register != '') ? this.uploadurl + this.currentNetwork.register + '?time=' + this.currentNetwork.time : this.imgurl : this.imgurl
                    this.shareUrl      = this.currentNetwork ? this.currentNetwork.share && (this.currentNetwork.share != null && this.currentNetwork.share != '') ? this.uploadurl + this.currentNetwork.share + '?time=' + this.currentNetwork.time : this.imgurl : this.imgurl
                },
                deep: true,
                immediate: true
            }
        },
        computed: {
            phone(){
                let country = this.country.find((item) => item.uuid == this.step.countryid)
                return country ? country.tel : ''
            },
            networkid(){
                return this.step.networkid
            },
            gender(){ 
                return [
                    {
                        text: this.$t('Masculino'),
                        id: 1
                    },
                    {
                        text: this.$t('Femenino'),
                        id: 0
                    },
                    {
                        text: this.$t('Otro'),
                        id: 2
                    }
                ]
            },
            validateUser(){

                let email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                if(email.test(this.step.username)){
                    return 'email'
                }

                let phone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
                if(this.step.username.length >= 7 && phone.test(this.step.username)){
                    return 'phone'
                }

                // Solo méxico
                if(this.hasMexico){
                    let username = /(?=.*[A-z])[A-z0-9]{18}/
                    if(this.step.username.length == 18 && username.test(this.step.username.trim())){
                        return 'username'
                    }
                }

                return ''
            },

            // Existe méxico
            hasMexico(){
                return this.country.some(c => c.uuid == 'd7e944ac-67a8-52b3-a303-6c520f928d69')
            }
        },
        created(){
            this.fetchConfig()
            this.loadState = this.loadOptions.normal
        },
        async mounted(){

            this.currentNetwork = window.NETWORK ? window.NETWORK : false
            let amplitude = require('amplitudejs')
            amplitude.init({
                songs: [
                    {
                        name: 'J. Ex-presidentes Audio 2',
                        url: '/public/audio/audio1.mp3',
                    },
                    {
                        name: 'J. Ex-presidentes Audio 2',
                        url: '/public/audio/audio2.mp3',
                    },
                    {
                        name: 'J. Ex-presidentes Audio 3',
                        url: '/public/audio/audio3.mp3',
                    },
                    {
                        name: 'J. Ex-presidentes Audio 4',
                        url: '/public/audio/audio4.mp3',
                    },
                    {
                        name: 'J. Ex-presidentes Audio 5',
                        url: '/public/audio/audio5.mp3',
                    },
                    {
                        name: 'J. Ex-presidentes Audio 6',
                        url: '/public/audio/audio6.mp3',
                    },
                    {
                        name: 'J. Ex-presidentes Audio 7',
                        url: '/public/audio/audio7.mp3',
                    },
                    {
                        name: 'J. Ex-presidentes Audio 8',
                        url: '/public/audio/audio8.mp3',
                    },
                    {
                        name: 'J. Ex-presidentes Audio 9',
                        url: '/public/audio/audio9.mp3',
                    }
                ]
            })

            for(let i = 0; i < 9; i++){

                let progress = document.getElementById('song-played-progress-'+i)

                progress.addEventListener('click', function(e){

                    if(amplitude.getActiveIndex() == i){

                        var offset = this.getBoundingClientRect()
                        var x = e.pageX - offset.left
                    }

                    amplitude.setSongPlayedPercentage((parseFloat(x)/parseFloat(this.offsetWidth)) * 100)
                })
            }

            this.loadPage = true
            await this.fetchForm()

			await this.saveCurrentRefCode()
			await this.currentRefCode()
            this.fetchCountry()

            if(this.activeNetwork){
                this.terms = true
            }
            this.loadPage = false

            this.openRegister()
        },
        methods: {

            openRegister(){
                
                this.zones = []
                this.zonei = -1
                this.state = []
                this.city = []
                this.step = _m(this.step, {
                    names     : '',
                    surname   : '',
                    surname2  : '',
                    username  : '',
                    rusername : '',
                    email    : '',
                    remail   : '',
                    password : '',
                    phone    : '',
                    countryid: '',
                    stateid  : '',
                    cityid   : '',
                    gender   : '',
                    birthdate: null,
                    code     : '',
                    zones    : [],
                    document : '',
                    custom   : {}
                })
                this.userid       = ''
                this.loadDisabled = false
                this.loadState    = ''
                this.loadCheck    = false
                this.loadingBtn   = false
                this.hasData      = false
                this.initialData  = false
                this.foundData    = {}
                this.customForm   = []
                this.availableRow = []
                this.loadPage     = false

                this.registerModal = true
                this.terms = false
            },

            noNetwork(network){
                return !this.activeNetwork && Object.keys(this.currentNetwork).length ? this.currentNetwork.alias != network : true
            },

            fetchCountry(){

                this.$api(this, (xhr) => {
                    xhr.get('/country', {
                        params: {
                            ab: 1, 
                            networkid: this.networkid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.country = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchState(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/state', {
                            params: {
                                countryid: this.step.countryid,
                                ab: 1, 
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.state = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchCity(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/city', {
                            params: {
                                stateid: this.step.stateid,
                                ab: 1, 
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.city = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchZone(item, push_empty_item = true){

                this.zones = this.zonei == -1 ? [] : this.zones.slice(0, this.zonei + 1)
                this.step.zones = this.zonei == -1 ? [] : this.step.zones.slice(0, this.zonei+1)
                
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/zone', {
                            params: {
                                cityid: this.step.cityid,
                                parent: this.zonei == -1 ? 0 : item,
                                ab: 1,
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                if(data.data.length){
                                    if (push_empty_item) {
                                        this.step.zones.push('')    
                                    }
                                    this.zones.push(data.data)
                                }
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchForm() {
                this.$api(this, (xhr) => {
                    xhr.get('/network/form', {
                        params: {
                            networkid: this.networkid
                        }
                    }).then((r) => {

                        let data = r.data
                        if (data.response) {

                            let custom = data.data.custom.map((c) => {
                                c.required   = c.required == 1 ? true : false
                                c.visibility = c.visibility == 1 ? true : false
                                return c
                            })

                            this.customForm = custom 
                            this.availableRow = data.data.available == null ? [] : data.data.available

                            for(let custom of this.customForm){
                                if(custom.type == 2){
                                    Vue.set(this.step.custom, custom.uuid, [])
                                }else{
                                    Vue.set(this.step.custom, custom.uuid, '')
                                }
                            }
                        }
                    }).catch(() => {})
                })
            },

            check(){

                if(this.activeNetwork && !this.terms){
                    return
                }

                let post = this._.cloneDeep(this.step)
                this.loadCheck = true

                this.$api(this, (xhr) => {
                    xhr.post('/register/check', this.$qs.stringify(post)).then(async (r) => {

                        let data = r.data
                        
                        if(!data.response){

                            this.loadCheck = false
                            if(data.message.length){
                                this.error = true
                                this.errorText = data.message
                            }else{
                                this.hasData = false
                                this.initialData = true
                            }
                        }else{
                            
                            data = data.data
                            this.hasData   = true
                            this.foundData = data

                            this.foundData.birthdate = this.$moment(this.foundData.birthdate).format('YYYY-MM-DD')

                            this.step = this._.merge(this.step, {
                                names    : this.foundData.names,
                                surname  : this.foundData.surname,
                                surname2 : this.foundData.surname2,
                                countryid: this.foundData.countryid,
                                stateid  : this.foundData.stateid,
                                cityid   : this.foundData.cityid,
                                birthdate: this.foundData.birthdate,
                                document : this.foundData.document,
                                gender   : this.foundData.gender,
                                phone    : this.foundData.phone 
                            })

                            await this.fetchState()
                            await this.fetchCity()
                            await this.fetchZone()

                            if(this.foundData.zones.length){

                                let zone  = this.foundData.zones[0]
                                let zones = this.foundData.zones.slice(1, this.foundData.zones.length)
                                this.step.zones.splice(0, 1, zone)

                                if(!zones.length){

                                    this.zonei = 0
                                    await this.fetchZone(zone)
                                }

                                let zlen = zones.length
                                for(let i = 1, j=0; i <= zlen; i++, j++){

                                    let parent = this.step.zones.slice(-1)[0]
                                    this.zonei = i

                                    this.step.zones.push(zones[j])
                                    
                                    let push_empty_item = zlen == i ? false : true
                                    await this.fetchZone(parent, push_empty_item)
                                }
                            }

                            this.loadCheck = false
                            this.initialData = true
                        }

                        if(this.validateUser == 'email'){
                            this.step.email  = this.step.username
                            this.step.remail = this.step.username
                        }

                        if(this.validateUser == 'phone'){
                            this.step.email  = this.step.username + '@wappid.com'
                            this.step.remail = this.step.username + '@wappid.com'
                            this.step.phone  = this.step.username
                        }

                        if(this.hasMexico && this.validateUser == 'username'){

                            this.step.email    = this.step.username + '@wappid.com'
                            this.step.remail   = this.step.rusername + '@wappid.com'
                            this.step.phone    = 5500000000
                            this.step.document = this.step.username
                        }

                    }).catch(() => {
                        this.loadCheck = false
                        this.hasData   = false
                    })
                })
            },

            save(){

                if(this.activeNetwork && !this.terms){
                    return
                }

                let post = this._.cloneDeep(this.step)
                post.zones = JSON.stringify(post.zones)

                let custom = this._.cloneDeep(this.step.custom)

                for(let c in custom){
                    if(typeof custom[c] != 'string'){
                        custom[c] = Array.isArray(custom[c]) ? custom[c] : custom[c].option
                    }
                }

                post.custom = JSON.stringify(custom)

                this.loadingBtn = true
                this.loadCheck  = true

                this.$api(this, (xhr) => {
                    xhr.post('/register', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.loadingBtn = false
                        this.loadCheck = false
                        
                        if(!data.response){
                            this.error = true
                            this.errorText = data.message
                        }else{
                            
                            data = data.data
                            this.$setItem('xtoken', data.token, () => {
                                this.$router.push({path: '/home' })
                            })
                        }
                    }).catch(() => {})
                })
            },
            
			async currentRefCode(){
				this.step.code = await this.$getItem('invite')
			},

            isAvailable(tag){
                
                if(!this.availableRow.length){
                    return true
                }

                let row = this.availableRow.find((r) => r.tag == tag)

                if(!row){
                    return true
                }

                return row.enable == 1 ? true : false
            },

            computedDepends(el, parent){

                let depends = this.customForm.filter((c) => {
                    return c.hasOwnProperty('depends') && c.depends == el.uuid
                })

                for(let depend of depends){

                    let index = this.customForm.findIndex((c) => c.uuid == depend.uuid)
                    if(parent != null && depend.options.hasOwnProperty(parent.uuid)){
                        Vue.set(this.customForm[index], 'coptions', depend.options[parent.uuid])
                    }else{
                        Vue.set(this.customForm[index], 'coptions', [])
                    }
                }
            },
        }
    }
    
</script>